import classnames from 'classnames'
import React, { useContext } from 'react'
import { OverridableComponent } from '../../utils/overridableComponent'
import classes from './Accordion.module.scss'
import { AccordionContext } from './AccordionContext'
import { AccordionPanelContext } from './AccordionPanelContext'

export type AccordionPanelProps = React.ComponentProps<'div'> & {
  i?: number
  isDarkMode?: boolean
  footer?: boolean
}

export const AccordionPanel: OverridableComponent<AccordionPanelProps> = ({ i, isDarkMode, footer, children, as: Component = 'div', ...props }) => {
  const { activeRows } = useContext(AccordionContext)
  let open = false
  const panelClasses = [props.className, classes['accordion__panel']]

  if (open) panelClasses.push(classes['accordion__panel--open'])

  if (isDarkMode) panelClasses.push(classes['accordion__darkmode'])

  if (footer) panelClasses.push(classes['accordion__footer'])

  if (i) {
    open = activeRows.includes(i)
  }

  return (
    <AccordionPanelContext.Provider value={{ i, open }}>
      <Component { ...props } className={classnames(panelClasses)}>
        {children}
      </Component>
    </AccordionPanelContext.Provider>
  )
}
