import React, { useContext } from 'react'
import { OverridableComponent } from '../../utils/overridableComponent'
import { AccordionPanelContext } from './AccordionPanelContext'
import classnames from 'classnames'
import classes from './Accordion.module.scss'
import { AnimatePresence, motion } from 'framer-motion'

export type AccordionPanelContentProps = {
  footer?: boolean
}

export const AccordionPanelContent: OverridableComponent<AccordionPanelContentProps> = ({ isDarkMode, footer, children, as: Component = 'div', ...props }) => {
  const { open } = useContext(AccordionPanelContext)
  const panelClasses = [classes['accordion__content__wrapper']]
  const contentClasses = [props.className]

  if (isDarkMode) panelClasses.push(classes['accordion__isDarkMode'])

  if (open && !footer) contentClasses.push(classes['accordion__content--open'])

  if (!footer) {
    contentClasses.push(classes['accordion__content'])
  } else {
    contentClasses.push(classes['accordion__footer__content'])
  }

  return (
    <AnimatePresence>
      {open ? (
        <motion.div
          className={classnames(panelClasses)}
          initial="collapsed"
          animate="open"
          exit="collapsed"
          transition={{
            type: 'spring',
            bounce: 0,
            duration: 0.3,
          }}
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
        >
          <Component
            { ...props }
            className={classnames(contentClasses)}
          >
            {children}
          </Component>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
