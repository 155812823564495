import classnames from 'classnames'
import { OverridableComponent } from '../../utils/overridableComponent'
import { Grid } from '../Grid'
import classes from './FlexibleContent.module.scss'
import { FlexibleContentColumn } from './FlexibleContentColumn'

export type Overflow = 'none' | '1_col' | '2_col'

export type FlexibleContentProps = {
  row?: boolean
  column?: boolean
  fluid?: boolean
  overflow?: {
    left?: Overflow
    right?: Overflow
  }
  customClass?: string
}

export const FlexibleContent: OverridableComponent<FlexibleContentProps> = ({ row, fluid, column, children, customClass, ...props }) => {
  if (column) {
    return (
      <FlexibleContentColumn { ...props }>
        {children}
      </FlexibleContentColumn>
    )
  }

  if (row) {
    return (
      <Grid container fluid={fluid} { ...props } className={classnames(customClass, props.className, classes.outer, classes.container)}>
        <Grid row { ...props } className={classnames(props.className, classes.row)}>
          {children}
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid className={classnames(props.className, classes.root)} fluid={fluid} { ...props }>
      {children}
    </Grid>
  )
}
