/* eslint-disable @typescript-eslint/no-explicit-any */
import router from 'next/router'
import StoryblokClient, { ISbRichtext, ISbStoriesParams, ISbStoryParams } from 'storyblok-js-client'

declare global {
  interface Window {
    storyblok: any
    StoryblokCacheVersion: number
  }
}

export class StoryblokService {
  private readonly devMode: boolean
  private readonly token: string
  private client: StoryblokClient
  private query: { [key: string]: string }

  constructor() {
    if (process.env['NODE_ENV'] !== 'production') {
      this.devMode = true
    } else {
      this.devMode = false
    }
    
    this.token = process.env['NEXT_PUBLIC_PREVIEW_STORYBLOK_API_TOKEN'] ?? ''
    this.client = new StoryblokClient({
      accessToken: this.token,
      cache: {
        clear: 'auto',
        type: 'memory',
      },
    })

    this.query = {}
  }

  getCacheVersion() {
    return this.client.cacheVersion
  }

  // ask Storyblok's Content API for content of story
  async get(path: string, params?: ISbStoryParams) {
    params = params || {}

    if (this.devMode || (typeof window !== 'undefined' && window.storyblok)) {
      params.version = 'draft'
    }

    if (typeof window !== 'undefined' && typeof window.StoryblokCacheVersion !== 'undefined') {
      params.cv = window.StoryblokCacheVersion
    }

    return this.client.get(path, params)
  }

  async getDocumentBySlug(slug: string, params?: {
    version?: string
    cv?: number
    resolve_relations?: string
    resolve_links?: 'url' | 'story' },
  ) {
    //@ts-ignore
    return this.get(`cdn/stories/${slug}`, { cv: Date.now(), ...params })
  }


  async getAll(pathExpr?: string, options: ISbStoriesParams = {}) {
    return this.client.getAll(pathExpr ?? 'cdn/stories', options)
  }

  async getRelated(folderName: string, pageNumber: number, per_page: number) {
    return this.client.get('cdn/stories', {
      starts_with: folderName,
      per_page: per_page,
      page: pageNumber,
      sort_by: 'first_published_at:desc',
    })
  }

  async getTeam() {
    return this.client.get('cdn/stories', {
      starts_with: 'team/',
      per_page: 100,
      sort_by: 'content.order:asc:int',
    })
  }

  async getFolder(folderName: string) {
    return this.client.get('cdn/stories', {
      starts_with: folderName,
      per_page: 100,
    })
  }

  async getCollection(id: string) {
    let response: string[]

    try {
      response = await this.client.getAll('cdn/stories', {
        'version': this.devMode ? 'draft' : 'published',
        'starts_with': `${id}/`,
        cv: Date.now(),
      })
    } catch (error) {
      console.error(error)

      return error
    }

    return response
  }

  // initialize the connection between Storyblok & Next.js in Visual Editor
  initEditor(reactComponent: { state: { story: { content: { _uid: string } } }; setState: (arg0: { story: any }) => void }) {
    if (typeof window !== 'undefined' && window.storyblok) {
      window.storyblok.init()

      // reload on Next.js page on save or publish event in Storyblok Visual Editor
      window.storyblok.on(['change', 'published'], () => router.reload())

      window.storyblok.on('input', (event: any | undefined) => {
        console.log('Event:', event)

        if (event?.story.content._uid === reactComponent.state.story.content._uid) {
          event.story.content = window.storyblok.addComments(event?.story.content, event.story.id)
          window.storyblok.resolveRelations(event.story, ['featured_posts.posts','fcp_related_content.posts','team_card.image_source', 'project_highlight.highlight', 'similar_projects.projects', 'project_listing_banner.banner'], () => {
            reactComponent.setState({
              story: event.story,
            })
          })
        }
      })
    }
  }

  setQuery(query: never) {
    this.query = query
  }

  getQuery(param: string) {
    return this.query[param]
  }

  renderRichText(content: ISbRichtext) {
    return this.client.richTextResolver.render(content)
  }

  bridge() {
    return (<script async src={'//app.storyblok.com/f/storyblok-latest.js?t=' + this.token}/>)
  }
}

const storyblokInstance = new StoryblokService()

export default storyblokInstance
