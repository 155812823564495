import classnames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { OverridableComponent } from '../../utils/overridableComponent'
import { Grid } from '../Grid'
import classes from './FlexibleContent.module.scss'

export const FlexibleContentColumn: OverridableComponent = ({ className, ...props }) => {
  const [isColumnRow, setIsColumnRow] = useState(false)
  const el = useRef<HTMLElement>(null)
  const columnClasses = [className, classes.mobileColumn, classes.column]
  // const isLarge = true

  // if (isLarge) columnClasses.push(classes['larger-column'])

  if (isColumnRow) columnClasses.push(classes['column--row'])

  const observer = useMemo(() => new ResizeObserver(() => {
    if (el.current && el.current.parentElement) {
      const topMargin = window.getComputedStyle(el.current).marginTop

      setIsColumnRow(el.current.parentElement.offsetTop !== el.current.offsetTop - Number.parseInt(topMargin))
    }
  }), [])

  useEffect(() => {
    if (el.current) {
      observer.observe(el.current)
    }
  }, [el])

  return (
    <Grid ref={el} item {...props} className={classnames(columnClasses)} />
  )
}