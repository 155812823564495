import { PropsWithChildren } from 'react'

export type PlusProps = {
  className: string
}

export const Plus: React.FC<PropsWithChildren<PlusProps>> = ({
  className,
}) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="17.13" height="16.637" viewBox="0 0 17.13 16.637">
    <g transform="translate(-1284.873 -6921.063)">
      <g transform="translate(1229.143 6707.896) rotate(45)">
        <path className="line" d="M193.633,151.123c6.179-6.557,2.307-2.606,4.687-5.051,1.158-1.194,1.688-1.6,2.878-2.766,1.259-1.227,1.277-.955,2.519-2.186a.321.321,0,0,0-.367-.513h0c-1.488.873-1.511.866-2.946,1.834a14.8,14.8,0,0,0-2.436,1.978c-2.588,2.282-4.963,5.271-4.963,6.183S193.633,151.123,193.633,151.123Z" transform="translate(3.83 -34.91)"/>
        <path className="line" d="M193.633,151.123c6.179-6.557,2.307-2.606,4.687-5.051,1.158-1.194,1.688-1.6,2.878-2.766,1.259-1.227,1.277-.955,2.519-2.186a.321.321,0,0,0-.367-.513h0c-1.488.873-1.511.866-2.946,1.834a14.8,14.8,0,0,0-2.436,1.978c-2.588,2.282-4.963,5.271-4.963,6.183S193.633,151.123,193.633,151.123Z" transform="translate(347.775 -87.083) rotate(90)"/>
      </g>
    </g>
  </svg>
)
