import { SbBlokData, storyblokEditable } from '@storyblok/react'
import React, { ComponentProps } from 'react'

export type PureSliceComponentProps<SliceProps> = {
  slice: SliceProps
}

export type SliceComponentProps<SliceProps extends SbBlokData> = {
  slice: SliceProps
}

export type SliceComponent<SP extends SbBlokData, P = Record<string, unknown>> = React.ComponentType<SliceComponentProps<SP> & P>

export type PureSliceComponent<SP extends SbBlokData, P = Record<string, unknown>> = React.ComponentType<PureSliceComponentProps<SP> & P>

export type WithSliceHOC = <SP extends SbBlokData, P = Record<string, unknown>>(Component: PureSliceComponent<SP, P>) => SliceComponent<SP, P>

export const withSlice: WithSliceHOC = (Component) => {
  const SliceComponent = (props: ComponentProps<typeof Component>) => {
    return (
      <div
        {...storyblokEditable(props.slice)}
        key={props.slice._uid}
      >
        <Component {...props} />
      </div>
    )
  }

  Object.assign(SliceComponent, Component)

  return SliceComponent
}
