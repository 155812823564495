import classnames from 'classnames'
import { useContext, useRef } from 'react'
import { OverridableComponent } from '../../utils/overridableComponent'
import { ToggleOpener } from '../icons/ToggleOpener'
import classes from './Accordion.module.scss'
import { AccordionContext } from './AccordionContext'
import { AccordionPanelContext } from './AccordionPanelContext'

export type AccordionPanelTitleProps = {
  footer?: boolean
}

export const AccordionPanelTitle: OverridableComponent<AccordionPanelTitleProps> = ({ isDarkMode, footer, children, as: Component = 'div', ...props }) => {
  const { i, open } = useContext(AccordionPanelContext)
  const { scroller, toggleRow } = useContext(AccordionContext)
  const titleClasses = [props.className, classes['accordion__title']]

  if (open) titleClasses.push(classes['accordion__title--open'])

  if (isDarkMode) titleClasses.push(classes['accordion__isDarkMode'])

  if (footer) {
    titleClasses.push(classes['accordion__footer'])
  } else {
    titleClasses.push(classes['accordion__normal'])
  }

  const myRef = useRef<HTMLDivElement>(null)

  const onClick = () => {
    if (scroller) {
      setTimeout(() => {
        if(!myRef) return
        myRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }, 100)
    }

    if (i) {
      toggleRow(i)
    }
  }

  return (
    <Component ref={myRef} { ...props } className={classnames(titleClasses)} onClick={onClick}>
      <div className={classes['accordion__title__text']}>
        {children}
      </div>
      <div className={classes['accordion__title__icon']}>
        <ToggleOpener open={open} footer={footer} />
      </div>
    </Component>
  )
}