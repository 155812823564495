import classnames from 'classnames'
import { PropsWithChildren } from 'react'
import { Underline } from '../Underline'
import { Plus } from '../icons/Plus'
import { RightArrow } from '../icons/RightArrow'
import { SpinnerIcon } from '../icons/SpinnerIcon'
import classes from './button.module.scss'

export type ButtonProps = React.ComponentProps<'button'> & {
  variant: 'primary' | 'primary-inverse' | 'no-icon' | 'secondary'
  icon: 'minus' | 'plus' | 'right-arrow' | ''
  isLoading?:boolean
}

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  className,
  variant,
  icon,
  isLoading,
  ...props
}) => {
  return (
    <button
      { ...props }
      className={classnames(className, classes.button, [classes[`button--${variant}`], classes[`button--${className}`]],
      )}
    >
      <div
        className={classes.button__inner}
      >
        {children}
        {classes.button__underline &&
          <Underline className={classes.button__underline}/>
        }
        {classes.button__underlinecover &&
          <Underline className={classes.button__underlinecover} />
        }

      </div>
      {icon === 'right-arrow' ? (
        isLoading ? <SpinnerIcon className={'spinnerIcon'}/> :
          <RightArrow
            className={classnames(classes['button__icon'], [classes[`button__icon--${icon}`]])}
          />
      ) : (icon === '' ? <div/> :
        <Plus
          className={classnames(classes['button__icon'], [classes[`button__icon--${icon}`]])}
        />)}
    </button>
  )
}
