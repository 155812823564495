import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import { isSsr } from '../utils/isSsr'
import { clamp } from '../utils/normalise'

// @ts-ignore
import iOS from 'is-ios'

const minTabletSize = 1024
const minREMSize = 1280

const getWindowWidth = () => {
  if (isSsr()) {
    return Number.POSITIVE_INFINITY
  }

  let width = window.innerWidth

  /**
   * This is basically a bunch of logic to get the real screen size for iPad/iPhone when the device is rotated
   */
  if (iOS) {
    const orientation = typeof screen.orientation === 'undefined' ? window.orientation : screen.orientation.angle
    const isLandscape = Math.abs(Number(orientation)) === 90

    width = isLandscape ? screen.height : screen.width
  }

  return width
}

const getScale = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const actual = getWindowWidth()

  return clamp(0, 1, actual / 1920)
}

export const InlineFontSetter = () => {
  const min = 14
  const max = 16
  const fontSize = useRemFontSize({ min, max })

  useEffect(() => {
    document.body.style.fontSize = `${fontSize}px`

    if (document.body.parentElement) {
      document.body.parentElement.style.fontSize = `${fontSize}px`
    }
  }, [fontSize])

  return (
    <Head>
      <style>{`
        html {
          font-size: ${max}px;
        }
      `}</style>
      <script  dangerouslySetInnerHTML={{ __html: `
        var breakpoints = JSON.parse('${JSON.stringify([1024, 1920])}');
        var fontSizes = JSON.parse('${JSON.stringify({ min, max })}')
        var keys = Object.keys(breakpoints)

        if (window.innerWidth < ${minREMSize}) {
          if (window.innerWidth < ${minTabletSize}) {
            document.querySelector('html').style.fontSize = '16px'
          } else {
            document.querySelector('html').style.fontSize = '14px'
          }
        } else {
          for (var i = 0; i < keys.length; i++) {
            var key = keys[i]
            var min = breakpoints[key][0]
            var max = breakpoints[key][1]
            var condition = '(max-width: ' + (max) + 'px)'
            if (window.matchMedia(condition).matches) {
              var scale = Math.min(1, Math.max(0, (window.innerWidth - min) / (max - min)))
              var difference = fontSizes.max - fontSizes.min
              var fontSize = Number(fontSizes.min + (difference * scale)).toFixed(1)
              document.querySelector('html').style.fontSize = fontSize + 'px'
              break
            }
          }
        }
      ` }} />
    </Head>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useRemFontSize = ({ min = 14, max = 16 } = {}) => {
  const [scale, setScale] = useState(getScale())
  const actual = getWindowWidth()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const onResize = () => window.requestAnimationFrame(() => setScale(getScale()))

      window.addEventListener('resize', onResize)
      window.addEventListener('orientationchange', onResize)

      return () => {
        window.removeEventListener('resize', onResize)
        window.removeEventListener('orientationchange', onResize)
      }
    }
  }, [])

  if (typeof window === 'undefined') {
    return max
  }

  if (actual < minREMSize) {
    // Fall back to normal font size on mobile

    return actual < minTabletSize ? 16 : 14
  }

  return Number(max * scale).toFixed(1)
}
